@keyframes show-menu {
  from {
    background: transparent;
  }

  to {
    background: var(--bright);
  }
}

@keyframes hide-menu {
  from {
    background: var(--bright);
  }

  to {
    background: transparent;
  }
}

@keyframes slide-down {
  0% {
    max-height: 1000px;
    padding: 11px 42px;
  }
  70% {
    padding: 11px 42px;
  }
  100% {
    max-height: 0;
    padding: 0;
  }
}

@keyframes slide-up {
  0% {
    max-height: 0;
    padding: 0;
  }
  1% {
    padding: 11px 42px;
  }
  100% {
    max-height: 1000px;
    padding: 11px 42px;
  }
}

@keyframes appearance {
  0% {
    display: initial;

    opacity: 0;
  }
  1% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes dark-to-light {
  from {
    border: 1px solid transparent;
    background: var(--primary-05);
  }
  to {
    border: 1px solid var(--primary);
    background: var(--bright);
  }
}

@keyframes light-to-dark {
  from {
    border: 1px solid var(--primary);
    background: var(--bright);
  }
  to {
    border: 1px solid transparent;
    background: var(--primary-05);
  }
}

@keyframes pulse {
  from {
    transform: scale(0.45);
  }
  50% {
    transform: scale(0.65);
  }
  to {
    transform: scale(0.45);
  }
}

@media screen and (max-width: 1200px) {
  @keyframes pulse {
    from {
      transform: scale(0.45);
    }
    50% {
      transform: scale(0.3);
    }
    to {
      transform: scale(0.45);
    }
  }
}

@keyframes a0_o {
  0% {
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);

    opacity: 1;
  }
  50% {
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);

    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}
