.Button {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;

  padding: 10px 28px;

  cursor: pointer;
  text-align: center;
  vertical-align: middle;

  color: var(--primary);
  border: 2px solid var(--bright);
  border-radius: 10px;
  background-color: var(--bright);

  --transition-button: background-color 100ms linear;
}

.Button_loader {
  width: 32px;
  height: 32px;
}

.Button .Button_loader {
  display: none;
}

.Button__loading .Button_text {
  display: none;
}

.Button__loading .Button_loader {
  display: initial;
}

.Button__loading {
  padding: 0 28px;
  padding-top: 4px;
  padding-bottom: 2px;
}

.Button__outlined {
  color: var(--bright);
  background-color: transparent;
}

.Button__loading.Button__outlined {
  cursor: initial;
}

@media (hover: hover) {
  .Button__outlined:hover:enabled:not(.Button__loading) {
    color: var(--primary);
    background-color: var(--bright);
  }
}

.Button__outlined:active:enabled:not(.Button__loading) {
  color: var(--bright);
  border-color: transparent;
  background-color: var(--bright-50);
}

.Button__outlined:disabled {
  cursor: initial;

  opacity: 0.5;
}

.Button::-moz-focus-inner {
  padding: 0;

  border: 0;
}

/* Main */
.Button__primary {
  color: var(--bright);
  background-color: var(--primary);
}

@media (hover: hover) {
  .Button__primary:hover:enabled:not(.Button__loading) {
    transition: var(--transition-button);

    background-color: var(--primary);
  }
}

.Button__primary:active {
  transition: var(--transition-button);

  background-color: var(--primary);
}

.Button__primary:disabled {
  cursor: initial;

  opacity: 0.5;
  color: var(--primary);
}

/* Secondary */
.Button__secondary {
  padding: 10px 18px;

  white-space: nowrap;

  border: 2px solid var(--primary);
  border-radius: 10px;
  background-color: var(--bright);
}

@media (hover: hover) {
  .Button__secondary:hover:enabled:not(.Button__loading) {
    transition: var(--transition-button);

    color: var(--bright);
    border: 2px solid transparent;
    background-color: var(--primary);
  }
}

.Button__secondary:active {
  transition: var(--transition-button);

  color: var(--bright);
  border: none;
  border: 2px solid transparent;
  background-color: var(--primary-70);
}

.Button__secondary:disabled {
  cursor: initial;

  opacity: 0.5;
}

/* Ghost */
.Button__ghost {
  color: var(--primary);
  border-color: var(--bright);
  background-color: var(--bright);
}

/* White buttons */
/* White Secondary */
.Button__whiteSecondary {
  padding: 10px 18px;

  white-space: nowrap;

  color: var(--bright);
  border: 2px solid var(--bright);
  border-radius: 10px;
  background-color: initial;
}

@media (hover: hover) {
  .Button__whiteSecondary:hover:enabled:not(.Button__loading) {
    transition: var(--transition-button);

    color: var(--primary);
    border: 2px solid transparent;
    background-color: var(--bright);
  }
}

.Button__whiteSecondary:active {
  transition: var(--transition-button);

  color: var(--bright);
  border: 2px solid transparent;
  background-color: var(--bright-50);
}

.Button__whiteSecondary:disabled {
  cursor: initial;

  opacity: 0.5;
}

/* White Main */
.Button__whitePrimary {
  padding: 10px 18px;

  white-space: nowrap;

  color: var(--primary);
  border: 2px solid var(--bright);
  border-radius: 10px;
  background-color: var(--bright);
}

@media (hover: hover) {
  .Button__whitePrimary:hover:enabled:not(.Button__loading) {
    transition: var(--transition-button);

    color: var(--primary);
    border: 2px solid var(--bright);
    background-color: var(--bright);
  }
}

.Button__whitePrimary:active {
  transition: var(--transition-button);

  color: var(--bright);
  border: 2px solid transparent;
  background-color: var(--bright-50);
}

.Button__whitePrimary:disabled {
  cursor: initial;

  opacity: 0.5;
}
