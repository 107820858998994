@import url("./fonts/index.css");
@import url("./theme/typography.css");
@import url("./theme/color-scheme.css");
@import url("./animations/index.css");
@import url("./components/button.css");
@import url("./components/dropdown.css");
@import url("./components/input.css");

html,
body {
  font-family: Rubik, sans-serif;
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  line-height: 20px;

  margin: 0;
  padding: 0;

  -webkit-overflow-scrolling: touch;
  scroll-snap-type: y mandatory;
  -webkit-text-size-adjust: 100%;
}
html {
  padding: env(safe-area-inset);
}

* {
  box-sizing: border-box;

  -webkit-tap-highlight-color: transparent;
}

a {
  text-decoration: none;

  color: inherit;
}

a:hover {
  text-decoration: none;
}

textarea {
  resize: none;

  border: none;
  outline: none;
}

input::placeholder,
textarea::placeholder {
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

:root {
  --height-men: 106px;
  --section-margin-top: 120px;
  --z-index-front-img: 50;
  --z-index-menu: 500;
}

.__section {
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;

  box-sizing: content-box;
  min-width: 1200px;
}

.__container {
  width: 1200px;
}

.version {
  font-size: 8px;
  line-height: 8px;

  position: fixed;
  z-index: 5000;
  top: 2px;
  left: 2px;

  padding: 1px 2px;

  text-transform: lowercase;
}
.version__highlight {
  color: white;
  background-color: brown;
}

/* Three classes for phone animation*/
.section_first {
  position: relative;

  width: 100%;
}

.section_second {
  position: relative;

  width: 100%;
}

.section_third {
  position: relative;

  width: 100%;
}

.webgl {
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  /* transition: transform 500ms linear; */

  pointer-events: none;

  outline: none;
}

.lil-gui {
  right: auto;
  left: 15px;

  display: none !important;
}

@media screen and (max-width: 1200px) {
  .__section {
    min-width: initial;
  }
}

img:not([src]) {
  visibility: hidden;
}
@-moz-document url-prefix() {
  img:-moz-loading {
    visibility: hidden;
  }
}

.Loader {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  min-height: var(--vh-px);

  transition: opacity 400ms ease-in-out;

  background: var(--gradient-main);
}

.Loader_leaves {
  position: absolute;
  top: 0;
  right: 0;

  pointer-events: none;
}

.Loader_leaves__svg {
  position: absolute;
  top: 0;
  right: 0;

  height: 100vh;
}

.Loader_logo {
  position: absolute;
  z-index: 1000;
  z-index: 500;
  top: 0;
  right: 0;
  bottom: 0;
  left: 50%;

  width: 25vw;

  transform: translateX(-50%);
}

.Loader__disapoint {
  opacity: 0;
}

@media screen and (max-width: 768px) {
  .Loader_leaves {
    transform: scale(3) !important;
  }
  .Loader_leaves__svg {
    position: absolute;
    top: 0;
    right: 0;

    height: 100vh;
  }
}

.Legal {
  width: 1200px;
  margin: 0 auto;
  padding: 1.25rem 1.75rem;
}

.Legal__section {
  display: flex;
  align-items: left;
  flex-direction: column;
}

.Legal__subTitle {
  font-size: 24px;

  margin: 38px 0 22px 0;
}

.Legal__link {
  margin: 10px 0;

  color: var(--dark-70);
}

.Legal__icon {
  margin: -2px 10px;
}

/* Add this to your existing index.css or create a new file for homepage-specific styles */

.CalculatorSection {
  background-color: var(--light-bg, #ffffff);
  padding: 80px 0;
}

.CalculatorSection_container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
